<template>
  <b-modal
    id="ImgModal"
    v-model="ImgModal"
    centered
    title="修改的证件照"
    size="lg"
    title-class="font-18"
    hide-footer
    style="min-height: 300px"
    class="d-flex justify-content-center align-items-center"
  >
    <div class="d-flex align-items-center justify-content-around">
      <div class="per-img-box">
        <div class="per-img-top flexList">原证件照</div>
        <div class="per-img-photo flexList justify-content-center">
          <img :src="getFileUrl(img1)" alt="加载失败" />
        </div>
        <div class="per-img-check p-2 text-info">
          <span>
            <i class="iconfont icon-a-ze-checkedCopy2 mr-2"></i>
            审核通过
          </span>
        </div>
      </div>
      <div class="per-img-box">
        <div class="per-img-top flexList">新上传证件照</div>
        <div class="per-img-photo flexList justify-content-center">
          <img :src="getFileUrl(img2)" alt="加载失败" />
        </div>
        <div class="per-img-check p-2 text-info">
          <span v-if="shzt == 2">
            <i class="iconfont icon-ze-clear text-danger mr-2"></i>
           审核未通过
          </span>
          <span v-else>
            <i class="iconfont icon-icon_minus-circled text-secondary mr-2"></i>
            待审
          </span>
        </div>
      </div>

      <!-- <div class="itemImg">
        <img :src="getFileUrl(img1)" alt="" />
        <div class="text-center">当前证件照</div>
      </div>
      <div class="itemImg">
        <img :src="getFileUrl(img2)" alt="" />
        <div class="text-center">
          修改的证件照
          <span :class="{ 'text-danger': shzt == 2, 'text-primary': !shzt }">{{
            shText
          }}</span>
        </div>
      </div> -->
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      ImgModal: false,
      img1: "",
      img2: "",
      shText: "",
      shzt: "",
    };
  },
  methods: {
    show(img1, img2, shzt) {
      console.log(shzt);
      this.img1 = img1;
      this.img2 = img2;
      this.ImgModal = true;
      this.shzt = shzt;
      if (shzt == 2) {
        this.shText = "审核未通过";
      } else {
        this.shText = "待审核";
      }
    },
    hide() {
      this.ImgModal = false;
    },
  },
};
</script>

<style>
.itemImg {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.itemImg img {
  height: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.itemImg div {
  font-size: 14px;
  margin-top: 10px;
}
</style>
